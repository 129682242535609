/**
 * Programs carousel display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if programs carousel present
 */

const carousel = (() => {
  // Render carousel
  function renderCarousel() {
    const $carousels = $('.programs-feature__carousel');

    $carousels.each((index, item) => {
      const $carousel = $(item);

      $carousel.slick({
        dots: false,
        infinite: false,
        speed: 300,
        slidesToShow: 1,
      });
    })
  }

  const init = () => {
    if (!$('.programs-feature__carousel').length) {
      return;
    }

    renderCarousel();
  };

  return {
    init,
  };
})();

export default carousel;

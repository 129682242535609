const footerSockDetector = (() => {
  const init = () => {
    if (Boolean($('.footer-sock').length)) {
      $('body').addClass('has-footer-sock');
    }
  };

  return {
    init,
  }
})();

export default footerSockDetector;
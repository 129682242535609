/**
 * Section Navigation
 * @param {*} $
 * @returns {init} Determine and run if section nav present
 */

const sectionNav = (() => {
  function toggleSectionNav(e) {
    const sectionNavBttn = e.target;
    const sectionNavDropdown = sectionNavBttn.nextElementSibling;
    const sectionNavigation = sectionNavBttn.parentElement.parentElement;
    const isSectionNavOpen = sectionNavBttn.getAttribute("aria-expanded");

    if (isSectionNavOpen == "false") {
      sectionNavBttn.setAttribute("aria-expanded", "true");
      sectionNavigation.classList.add("js-expanded");
      $(sectionNavDropdown).slideDown(300);
    } else if (isSectionNavOpen == "true") {
      sectionNavBttn.setAttribute("aria-expanded", "false");
      sectionNavigation.classList.remove("js-expanded");
      $(sectionNavDropdown).slideUp(300);
    }
  }

  function watchScreenResize() {
    const sectionNavigation = document.querySelectorAll(".section-nav")[0];
    const sectionNavBttn =
      sectionNavigation.querySelectorAll(".section-nav__bttn")[0];

    window.addEventListener("resize", (e) => {
      const windowWidth = e.target.outerWidth;

      if (windowWidth > 1024) {
        sectionNavigation.classList.remove("js-expanded");
        sectionNavBttn.setAttribute("aria-expanded", "false");
      }
    });
  }

  const init = () => {
    if (!$(".section-nav").length) {
      return;
    }

    $(".section-nav__bttn").on("click", toggleSectionNav);
    watchScreenResize();
  };

  return {
    init,
  };
})();

export default sectionNav;

/**
 * Tabs display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if tabs present
 */

const carousel = (() => {
  const ANIMATION_SPEED = 150;

  function tabsToggle(event) {
    event.preventDefault();

    if ($(this).hasClass('tabs__toggle--active')) return;

    $('.tabs__toggle')
      .attr('aria-selected', 'false')
      .removeClass('tabs__toggle--active');

    $('.tabs__item')
      .stop()
      .fadeOut(ANIMATION_SPEED)
    
    setTimeout(() => {
      $(this)
      .addClass('tabs__toggle--active')
      .attr('aria-selected', 'true');
      
      $($(this).attr('href'))
      .stop()
      .fadeIn(ANIMATION_SPEED)
    }, ANIMATION_SPEED)
  }

  const init = () => {
    if (!$('.tabs').length) {
      return;
    }

    $('.tabs__toggle').on('click', tabsToggle)
    $('.tabs__toggle:first').click();
  };

  return {
    init,
  };
})();

export default carousel;

const alert = (() => {
  const init = () => {
    $('.alert__close').on('click', (e) => {
      $(e.target).closest('.alert').slideUp(300);
    });
  }

  return {
    init,
  }
})();

export default alert;
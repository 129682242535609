const mainHeader = (() => {
  let $bodyHtml,
      $body,
      $window;

  const MAIN_NAV_OPENED_CLASS_NAME = 'nav-opened';
  const SEARCH_OPENED_CLASS_NAME = 'search-opened';
  const HEADER_HIDDEN_CLASS_NAME = 'header-hidden';
  const HEADER_ON_TOP_CLASS_NAME = 'is-on-top';
  const DESKTOP_BREAKPOINT = 1024;
  const ANIMATION_SPEED = 300;

  const defineGlobalVariables = () => {
    $window = $(window);
    $bodyHtml = $('html, body');
    $body = $('body');
  };

  const headerHeightDetector = () => {
    $window.on('load resize', () => {
      $body.css({'--header-height': `${$('.main-header').outerHeight()}px`});
    });
  };

  const headerPosition = () => {
    const $mainHeader = $('.main-header');
    let lastScrollPos = 0;
    let currentScrollPos = 0;
    const $alertHeight = $('.alert').outerHeight() || 0;

    $window.on('scroll load', () => {
      currentScrollPos = $window.scrollTop();

      if ((lastScrollPos < currentScrollPos) && ($window.scrollTop() > ($('.main-header').outerHeight()) + $alertHeight)) {
        $mainHeader.addClass(HEADER_HIDDEN_CLASS_NAME);
      } else {
        $mainHeader.removeClass(HEADER_HIDDEN_CLASS_NAME);
      }

      if (currentScrollPos === 0) {
        $mainHeader.addClass(HEADER_ON_TOP_CLASS_NAME);
      } else {
        $mainHeader.removeClass(HEADER_ON_TOP_CLASS_NAME);
      }
      
      lastScrollPos = currentScrollPos;
    });
  };

  const navPlaceDefiner = () => {
    let scrollbarWIdth;
    const $mainNavHolder = $('.main-nav-holder');
    const $secondaryNavHolder = $('.secondary-nav__holder');
    const $mobileNavHolder = $('.mobile-tablet__nav-holder .nav-wrapper');
    let $mainNav,
        $secondaryNav;

    $window.on('load resize', () => {
      scrollbarWIdth = parseFloat($body.css('--scrollbar-width'));

      if (($window.width() + scrollbarWIdth) <= DESKTOP_BREAKPOINT && !$mobileNavHolder.find('.main-nav').length) {
        $mainNav = $mainNavHolder.find('.main-nav').detach();
        $secondaryNav = $secondaryNavHolder.find('.secondary-nav').detach();

        $mobileNavHolder
          .append($mainNav, $secondaryNav);
      } else if (($window.width() + scrollbarWIdth) > DESKTOP_BREAKPOINT && $mobileNavHolder.find('.main-nav').length) {
        $mainNav = $mobileNavHolder.find('.main-nav').detach();
        $secondaryNav = $mobileNavHolder.find('.secondary-nav').detach();

        $mainNavHolder.append($mainNav);
        $secondaryNavHolder.append($secondaryNav);
      }
    });
  };

  const mainNavToggle = () => {
    const $navigationOpener = $('.navigation-opener');
    const $mobileNavHolder = $('.mobile-tablet__nav-holder');

    $navigationOpener.on('click', () => {
      if (!$bodyHtml.hasClass(MAIN_NAV_OPENED_CLASS_NAME)) {
        $bodyHtml.addClass(MAIN_NAV_OPENED_CLASS_NAME);
        $navigationOpener.attr('aria-expanded', "true");
        $mobileNavHolder.attr('aria-hidden', "false");
      } else {
        $bodyHtml.removeClass(MAIN_NAV_OPENED_CLASS_NAME);
        $navigationOpener.attr('aria-expanded', "false");
        $mobileNavHolder.attr('aria-hidden', "true");
      }
    });
  };

  const mainSubNavToggle = () => {
    const $subnavOpeners = $('.main-nav__submenu-opener');

    $subnavOpeners.each((index, item) => {
      const $opener = $(item);
      const $submenu = $opener.siblings('.main-nav__submenu');

      $opener.on('click', () => {
        if ($opener.attr('aria-expanded') === "false") {
          $opener.attr('aria-expanded', 'true');
          $submenu
            .stop()
            .slideDown(ANIMATION_SPEED)
            .attr('aria-hidden', 'false');
        } else {
          $opener.attr('aria-expanded', 'false');
          $submenu
            .stop()
            .slideUp(ANIMATION_SPEED)
            .attr('aria-hidden', 'true');
        }
      });
    });
  };

  const mainNavDesktopControls = () => {
    const OPENED_CLASS_NAME = 'is-active';
    const $mainNavLinks = $('.main-nav__link');

    $mainNavLinks.each((index, link) => {
      const $link = $(link);

      $link.on('focus', (e) => {
        if($window.width() > DESKTOP_BREAKPOINT) {
          $('.main-nav__item').removeClass(OPENED_CLASS_NAME);
          $('.main-nav__submenu').attr('aria-hidden', 'true');

          $link.closest('.main-nav__item').addClass(OPENED_CLASS_NAME);
          $link.siblings('.main-nav__submenu').attr('aria-hidden', 'false');
        }
      });
    });

    $('.main-header__bottom').on('mouseenter', (e) => {
      $('.main-header__bottom').addClass('mouse-over');
    });

    $('.main-header__bottom').on('mouseleave', (e) => {
      $('.main-header__bottom').removeClass('mouse-over');
    });

    $('.main-nav__item').on('mouseenter', (e) => {
      if ($window.width() > DESKTOP_BREAKPOINT) {
        $('.main-nav__item').removeClass(OPENED_CLASS_NAME);
        $('.main-nav__submenu').attr('aria-hidden', 'true');

        $(e.currentTarget)
          .addClass(OPENED_CLASS_NAME)
          .find('.main-nav__submenu')
          .attr('aria-hidden', 'false');
      }
    });

    $('.main-nav__submenu-link')
      .last()
      .on('keydown', (e) => {
        if($window.width() > DESKTOP_BREAKPOINT && e.keyCode === 9 && !e.shiftKey) {
          $('.main-nav__item').removeClass(OPENED_CLASS_NAME);
          $('.main-nav__submenu').attr('aria-hidden', 'true');
        }
      });

      $('.main-nav__item').on('mouseleave', () => {
        $('.main-nav__item').removeClass(OPENED_CLASS_NAME);
        $('.main-nav__submenu').attr('aria-hidden', 'true');
      });

    $('.main-nav__link, .main-nav__submenu-link').on('keydown', (e) => {
      if (e.keyCode === 27) {
        const $menuLink = $(e.target).closest('.main-nav__item').find('.main-nav__link');
        if ($menuLink.length) {
          $menuLink.focus();
        }
        $('.main-nav__item').removeClass(OPENED_CLASS_NAME);
        $('.main-nav__submenu').attr('aria-hidden', 'true');
      }
    });

    $(document).on('keydown', (e) => {
      if (e.keyCode === 27 && $('.main-header__bottom').hasClass('mouse-over')) {
        $('.main-nav__item').removeClass(OPENED_CLASS_NAME);
        $('.main-nav__submenu').attr('aria-hidden', 'true');
      }
    });
  }

  const mobileKeyboardNav = () => {
    const $navOpener = $('.navigation-opener');
    const $secondaryNavOpener = $('.secondary-nav__submenu-opener');
    const $subnavLastLink = $('.secondary-nav__submenu-link').last();
    const $mainNavLinkFirst = $('.main-nav__link').first();

    $secondaryNavOpener.on('keydown', (e) => {
      if($window.width() <= DESKTOP_BREAKPOINT) {
        if (e.keyCode === 9 && !e.shiftKey && $secondaryNavOpener.attr('aria-expanded') !== 'true') {
          e.preventDefault();
          $navOpener.focus();
        }
      }
    });

    $subnavLastLink.on('keydown', (e) => {
      if($window.width() <= DESKTOP_BREAKPOINT) {
        if (e.keyCode === 9 && !e.shiftKey) {
          e.preventDefault();
          $navOpener.focus();
        }
      }
    });
    
    $mainNavLinkFirst.on('keydown', (e) => {
      if($window.width() <= DESKTOP_BREAKPOINT) {
        if (e.keyCode === 9 && e.shiftKey) {
          e.preventDefault();
          $navOpener.focus();
        }
      }
    });

    $navOpener.on('keydown', (e) => {
      if($window.width() <= DESKTOP_BREAKPOINT && $body.hasClass(MAIN_NAV_OPENED_CLASS_NAME)) {
        if (e.keyCode === 9 && !e.shiftKey) {
          e.preventDefault();
          $mainNavLinkFirst.focus();
        } else if (e.keyCode === 9 && e.shiftKey) {
          e.preventDefault();
          if ($secondaryNavOpener.attr('aria-expanded') !== 'true') {
            $secondaryNavOpener.focus();
          } else {
            $subnavLastLink.focus();
          }
        }
      }
    });
  };

  const secondarySubNavToggle = () => {
    const $subnavOpeners = $('.secondary-nav__submenu-opener');
    const OPENED_CLASS_NAME = 'is-opened';

    $subnavOpeners.each((index, item) => {
      const $opener = $(item);
      const $submenu = $opener.siblings('.secondary-nav__submenu');
      const $parentItem = $opener.closest('.secondary-nav__item');

      $opener.on('click', () => {
        if ($opener.attr('aria-expanded') === "false") {
          $parentItem.addClass(OPENED_CLASS_NAME);
          $opener.attr('aria-expanded', 'true');

          if($window.width() <= DESKTOP_BREAKPOINT) {
            $submenu
              .stop()
              .slideDown(ANIMATION_SPEED);
          } else {
            $submenu
              .stop()
              .fadeIn(ANIMATION_SPEED);
          }

          $submenu.attr('aria-hidden', 'false');
        } else {
          $parentItem.removeClass(OPENED_CLASS_NAME);
          $opener.attr('aria-expanded', 'false');

          if($window.width() <= DESKTOP_BREAKPOINT) {
            $submenu
            .stop()
              .slideUp(ANIMATION_SPEED);
          } else {
            $submenu
              .stop()
              .fadeOut(ANIMATION_SPEED);
          }

          $submenu.attr('aria-hidden', 'true');
        }
      });
    });
  };

  const secondarySubNavDesktopControls = () => {
    const $subnavOpener = $('.secondary-nav__submenu-opener');
    const $subnavLastLink = $('.main-header .secondary-nav__submenu-link').last();

    $subnavOpener.on('keydown', (e) => {
      if($window.width() > DESKTOP_BREAKPOINT && e.keyCode === 9 && e.shiftKey && $subnavOpener.attr('aria-expanded') === 'true') {
        e.preventDefault();
        $subnavLastLink.focus();
      }
    });

    $subnavLastLink.on('keydown', (e) => {
      if($window.width() > DESKTOP_BREAKPOINT && !e.shiftKey) {
        e.preventDefault();
        $subnavOpener.focus();
      }
    });
  };

  const searchControls = () => {
    const $searchOpener = $('.search-opener');
    const $searchClose = $('.search-close');
    const $searchForm = $('.search__form');
    const $searchInput = $('.search__input');
    const $navigationOpener = $('.navigation-opener');
    const $mobileNavHolder = $('.mobile-tablet__nav-holder');

    $searchOpener.on('click', () => {
      $body.addClass(SEARCH_OPENED_CLASS_NAME);
      $bodyHtml.removeClass(MAIN_NAV_OPENED_CLASS_NAME);
      $navigationOpener.attr('aria-expanded', "false").find('.visually-hidden').text('open navigation');
      $mobileNavHolder.attr('aria-hidden', "true");
      $searchOpener.attr('aria-expanded', 'true');
      $searchForm.attr('aria-hidden', 'false');

      setTimeout(() => {
        $searchInput.focus();
      }, ANIMATION_SPEED);
    });

    $searchClose.on('click', () => {
      $body.removeClass(SEARCH_OPENED_CLASS_NAME);
      $searchOpener.attr('aria-expanded', 'false');
      $searchForm.attr('aria-hidden', 'true');
      $searchOpener.focus();
    });

    $searchClose.on('keydown', (e) => {
      if (e.keyCode === 9 && !e.shiftKey) {
        e.preventDefault();
        $searchInput.focus();
      }
    });

    $searchInput.on('keydown', (e) => {
      if (e.keyCode === 9 && e.shiftKey) {
        e.preventDefault();
        $searchClose.focus();
      }
    });
  };

  const init = () => {
    defineGlobalVariables();
    navPlaceDefiner();
    headerHeightDetector();
    headerPosition();
    mainNavToggle();
    mainSubNavToggle();
    secondarySubNavToggle();
    mobileKeyboardNav();
    searchControls();
    secondarySubNavDesktopControls();
    mainNavDesktopControls();
  };

  return {
    init,
  }
})();

export default mainHeader;
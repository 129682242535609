const featuredCalendarFilter = (() => {
  const init = () => {
    const $featuredEventsSection = $('.featured-events');
    const $filterButtons = $featuredEventsSection.find('.events-filter-campus__link');
    const $filterSelect = $featuredEventsSection.find('.events-filter-campus__select .select__field');

    const $calendars = $featuredEventsSection.find('.events__content');

    const applyFilters = (campusName) => {
      // reset state before filtering
      $calendars
          .attr('aria-hidden', 'true')
          .hide();

      $filterButtons
        .attr('aria-current', 'false')
        .closest('.events-filter-campus__item')
        .removeClass('events-filter-campus__item--active');
      
      // filter items
      $calendars.each((index, campus) => {
        if ($(campus).data('campus-name').toLowerCase() === campusName.toLowerCase()) {
          $(campus)
            .attr('aria-hidden', 'false')
            .show();
        }
      });

      $filterButtons.each((index, link) => {
        if ($(link).text().toLowerCase() === campusName.toLowerCase()) {
          $(link)
            .attr('aria-current', 'true')
            .closest('.events-filter-campus__item')
            .addClass('events-filter-campus__item--active');
        }
      });
    };

    $filterSelect.on('change', (e) => {
      applyFilters($filterSelect.val());
    });

    $filterButtons.on('click', (e) => {
      e.preventDefault();
      $filterSelect.val($(e.target).text()).change();
    });
  };

  return {
    init,
  };
})();

export default featuredCalendarFilter;

/* global Vue */
/* global VueRouter */

function getFilterValuesProgramType(_this, property) {
  /**
   * return an array of all the values from a certain property,
   * for getting all the filters we need in the sidebar
   */
  const seenIcon = [];

  return _this.items.reduce((accumulator, selectedValue) => {
    selectedValue[property].forEach((item) => {
      if (typeof item === 'object') {
        if (seenIcon.indexOf(item.name) === -1) {
          accumulator.push(item);
          seenIcon.push(item.name);
        }
      }
    });

    return accumulator;
  }, []);
}

function getFilterValues(_this, property) {
  /**
   * return an array of all the values from a certain property,
   * for getting all the filters we need in the sidebar
   */
  return _this.items.reduce((accumulator, currentValue) => {
    currentValue[property].forEach(item => {
      if (accumulator.indexOf(item) === -1) {
        accumulator.push(item);
      }
    });
    return accumulator;
  }, []);
}

export default {
  init() {
    if (!document.getElementById('program-list')) {
      return;
    }

    // 1. Define route components.
    // These can be imported from other files
    const Home = {}

    // 2. Define some routes
    // Each route should map to a component.
    // We'll talk about nested routes later.
    const routes = [
      {
        path: `${window.location.pathname}`,
        component: Home,
        query: {
          credType: "program_credential_all",
          areaOfInterest: "program_aoi_all",
          programDivision: "program_division_all",
          programType: "program_type_all",
          sortBy: "alphabetical",
        }
      }
    ]

    // 3. Create the router instance and pass the `routes` option
    // You can pass in additional options here, but let's
    // keep it simple for now.
    const router = VueRouter.createRouter({
      // 4. Provide the history implementation to use. 
      history: VueRouter.createWebHistory(),
      routes, // short for `routes: routes`
    })

    const app = Vue.createApp({});

    app.component('program-list', {
      data() {
        return {
          items: [],
          sortBy: 'alphabetical',
          currCredentialType: ['program_credential_all'],
          currAreaOfInterest: ['program_aoi_all'],
          currProgramDivision: ['program_division_all'],
          currType: ['program_type_all'],
          showFilters: false,
        };
      },

      props: ['url'],

      created() {
        const params = this.getQueryVariable();

        if (params.length > 1) {
          if (params[0] !== undefined) {
            const types = params[0].split(',');

            this.currCredentialType = types.map(item => {
              return decodeURIComponent(item);
            });
          }
          if (params[1] !== undefined) {
            const areaOfInterest = params[1].split(',');

            this.currAreaOfInterest = areaOfInterest.map(item => {
              return decodeURIComponent(item);
            });
          }
          if (params[2] !== undefined) {
            const ProgramDivision = params[2].split(',');

            this.currProgramDivision = ProgramDivision.map(item => {
              return decodeURIComponent(item);
            });
          }
          if (params[3] !== undefined) {
            const type = params[3].split(',');

            this.currType = type.map(item => {
              return decodeURIComponent(item);
            });
          }


          // if (params[2] !== undefined) {
          //   this.sortBy = decodeURIComponent(params[3]);
          // }
        }

        $.getJSON(this.url, json => {
          this.items = _.sortBy(json, [item => item.title.toLowerCase()]);
        });
      },

      template: `
        <div class="program-finder">
          <div :class="['program-finder__filters-panel', { 'program-finder__filters-panel--open': showFilters }]">
            <div class="program-finder__filters-control">
              <button
                class="program-finder__button-filters button-filters"
                :aria-expanded="showFilters ? 'true' : 'false' "
                @click="toggleFilters"
              >
                <span class="button-filters__text">Filters</span>
                <span class="button-filters__icon"></span>
              </button>
              <div class="program-finder__select select">
                <label for="sort-by">Sort By</label>
                <div class="select__wrapper">
                  <select @change="setParams" v-model="sortBy" id="sort-by">
                    <option value="areaOfInterest">Area of Interest</option>
                    <option value="alphabetical">Alphabetical</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="program-finder__filters filters">
              <div class="program-finder__filters-inner">
                <fieldset class="filters__fieldset">
                  <legend class="filters__legend">Credential Type</legend>
                  <div class="filters__fields-wrapper">
                    <div class="filters__item">
                        <input
                          type="checkbox"
                          name="program-credential"
                          v-model="currCredentialType"
                          id="program-credential-all"
                          @change="setParams"
                          value="program_credential_all" />
                      <label for="program-credential-all">All Credentials</label>
                    </div>
                    <div class="filters__item" v-for="(item, index) in credentialType">
                      <input
                        type="checkbox"
                        v-model="currCredentialType"
                        @change="setParams"
                        name="program-credential"
                        :id="'program-credential-'+index"
                        :value="item" />
                      <label :for="'program-credential-'+index">{{ item }}</label>
                    </div>
                  </div>
                </fieldset>
                <fieldset class="filters__fieldset">
                  <legend class="filters__legend">Area of Interest</legend>
                  <div class="filters__fields-wrapper">
                    <div class="filters__item">
                        <input
                          type="checkbox"
                          name="program-aoi"
                          v-model="currAreaOfInterest"
                          id="program-aoi-all"
                          @change="setParams"
                          value="program_aoi_all" />
                      <label for="program-aoi-all">All Areas of Interest</label>
                    </div>
                    <div class="filters__item" v-for="(item, index) in areaOfInterest">
                      <input
                        type="checkbox"
                        v-model="currAreaOfInterest"
                        @change="setParams"
                        name="program-aoi"
                        :id="'program-aoi-'+index"
                        :value="item" />
                      <label :for="'program-aoi-'+index">{{ item }}</label>
                    </div>
                  </div>
                </fieldset>
                <fieldset class="filters__fieldset">
                  <legend class="filters__legend">Program Division</legend>
                  <div class="filters__fields-wrapper">
                    <div class="filters__item">
                        <input
                          type="checkbox"
                          name="program-division"
                          v-model="currProgramDivision"
                          id="program-division-all"
                          @change="setParams"
                          value="program_division_all" />
                      <label for="program-division-all">All Divisons</label>
                    </div>
                    <div class="filters__item" v-for="(item, index) in programDivision">
                      <input
                        type="checkbox"
                        v-model="currProgramDivision"
                        @change="setParams"
                        name="program-division"
                        :id="'program-division-'+index"
                        :value="item" />
                      <label :for="'program-division-'+index">{{ item }}</label>
                    </div>
                  </div>
                </fieldset>
                <fieldset class="filters__fieldset">
                  <legend class="filters__legend">Program Types</legend>
                  <div class="filters__fields-wrapper">
                    <div class="filters__item">
                      <input
                        type="checkbox"
                        name="program-type"
                        v-model="currType"
                        id="program-type-all"
                        @change="setParams"
                        value="program_type_all" />
                      <label for="program-type-all">All Program Types</label>
                    </div>
                    <div class="filters__item" v-for="(item, index) in programType">
                      <input
                        type="checkbox"
                        name="program-type"
                        @change="setParams"
                        v-model="currType"
                        :id="'program-type-'+index"
                        :value="item" />
                      <label :for="'program-type-'+index">{{ item.name }} <span class="filters__type">{{item}}</span></label>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
          <div class="program-finder__list program-list" v-if="filteredResults.length">
            <ul class="program-list__items">
              <li class="program-list__item" v-for="(item, index) in filteredResults">
                <a class="program-list__link" :href="item.url"> {{ item.title }}</a>
                <span class="program-list__cred"> ({{ item.credentialType.join(', ') }}) </span>
              </li>
            </ul>
          </div>
          <div class="program-finder__list program-list" v-if="filteredResults.length == 0">
            <h2>No results were found for the given filter configuration.</h2>
          </div>
        </div>
      `,

      computed: {
        filteredResults() {
          let filteringTemp = this.items;

          // Filtering logic for each filter

          // Credential
          if (!this.currCredentialType.includes('program_credential_all') && this.currCredentialType.length) {
            filteringTemp = filteringTemp.filter(item => {
              return item.credentialType.some(currItem => this.currCredentialType.includes(currItem));
            });
          }

          // Area of interest
          if (!this.currAreaOfInterest.includes('program_aoi_all') && this.currAreaOfInterest.length) {
            filteringTemp = filteringTemp.filter(item => {
              return item.areaOfInterest.some(currItem => this.currAreaOfInterest.includes(currItem));
            });
          }

          // Program Division
          if (!this.currProgramDivision.includes('program_division_all') && this.currProgramDivision.length) {
            filteringTemp = filteringTemp.filter(item => {
              return item.programDivision.some(currItem => this.currProgramDivision.includes(currItem));
            });
          }

          // Program Type
          if (!this.currType.includes('program_type_all') && this.currType.length) {
            filteringTemp = filteringTemp.filter((item) => {
              return (
                this.currType.filter((x) => {
                  return item.programType.some((a) => {
                    return a === x;
                  });
                }).length > 0)
            });
          }

          return filteringTemp;
        },

        // Get the items for each filter category
        credentialType() {
          return getFilterValues(this, 'credentialType');
        },

        areaOfInterest() {
          return getFilterValues(this, 'areaOfInterest');
        },

        programDivision() {
          return getFilterValues(this, 'programDivision');
        },

        programType() {
          return getFilterValues(this, 'programType');
        },

        // programType() {
        //   return _(getFilterValuesProgramType(this, 'programType'))
        //     .sortBy([(item) => item.abbreviation.toLowerCase()])
        //     .value();
        // },

        // Sortby logic
        groupedResults() {
          if (this.sortBy === 'areaOfInterest') {
            // Create a new program array with each item inside a nested array, grouped by dept
            const temp = this.filteredResults.reduce((acc, obj) => {
              // Drill down to each department item for each program
              obj.areaOfInterest.forEach(item => {
                /**
                 * Add program to the appropiate department array,
                 * Create new department array if there isn't one
                 */
                if (!acc[item]) {
                  acc[item] = [];
                }
                acc[item].push(obj);
              });
              return acc;
            }, {});
            return _(temp)
              .map((value, key) => {
                /**
                 * re-organize that array item so that it has the
                 * department and data as separate property/value pairs
                 * and sort it
                 */
                return {
                  type: key,
                  data: value,
                };
              })
              .sortBy([item => item.type.toLowerCase()])
              .value();
            // eslint-disable-next-line no-else-return
          } else {
            return _(this.filteredResults)
              .groupBy(item => item.title[0].toUpperCase())
              .map((value, key) => {
                return {
                  type: key,
                  data: value,
                };
              })
              .value();
          }
        },
      },

      methods: {
        getQueryVariable() {
          const href = window.location.href
          const index = href.indexOf('#')
          let pairs = (index === -1 ? '' : decodeURIComponent(href.slice(index + 1)));
          pairs = pairs.split(', ');

          this.$route.query.credType = pairs[0];
          this.$route.query.areaOfInterest = pairs[1];
          this.$route.query.programDivision = pairs[2];
          this.$route.query.programType = pairs[3];

          return pairs;
        },

        toggleFilters() {
          const HERO_CLASS_NAME = 'hero--without-bottom-border';
          const $hero = $('.hero');

          $('.program-finder__filters').slideToggle().css({ display: 'grid' });

          if ($hero.length > 0 && $(window).width() < 700) {
            $hero.toggleClass(HERO_CLASS_NAME)
          }

          this.showFilters = !this.showFilters;
        },

        setParams() {
          let credType = JSON.parse(JSON.stringify(this.currCredentialType));
          let areaOfInterest = JSON.parse(JSON.stringify(this.currAreaOfInterest));
          let programDivision = JSON.parse(JSON.stringify(this.currProgramDivision));
          let programType = JSON.parse(JSON.stringify(this.currType));
          // let sortBy = JSON.parse(JSON.stringify(this.sortBy));

          // const { sortBy } = this;

          if (credType == '') {
            credType = 'program_credential_all';
          }

          if (areaOfInterest == '') {
            areaOfInterest = 'program_aoi_all';
          }

          if (programDivision == '') {
            programDivision = 'program_division_all'
          }

          if (programType == '') {
            programType = 'program_type_all'
          }

          this.$route.query.credType = credType;
          this.$route.query.areaOfInterest = areaOfInterest;
          this.$route.query.programDivision = programDivision;
          this.$route.query.programType = programType;
          // this.$route.query.sortBy = sortBy;

          history.pushState({}, null, `${this.$route.path}#${encodeURIComponent(credType)}, ${encodeURIComponent(areaOfInterest)}, ${encodeURIComponent(programDivision)}, ${encodeURIComponent(programType)}`);
        },

        showFiltersPanel() {
          this.showFilters = true;
          this.searchTerms = '';
        },

        showSearchPanel() {
          this.resetFilters();
          this.showFilters = false;
        },

        resetFilters() {
          this.currType = [];
          this.currAreaOfInterest = [];
          this.setParams();
        },
      },
    });

    app.use(router);

    app.mount('#program-list')
  },
};

const campusFeature = (() => {
  const HOVER_CLASS_NAME = 'is-hovered';
  const FOCUS_CLASS_NAME = 'is-focused';
  let $campusGrid,
      $campusFeature,
      $campusGridItems,
      $window,
      $bodyHtml,
      $modalCarousel,
      $carousel,
      $lastClickedOpener,
      $openers,
      $slides,
      $closeButtons,
      $headerPlacer,
      $header;

  const defineVariables = () => {
    $window = $(window);
    $campusFeature = $('.campus-feature');
    $campusGrid = $campusFeature.find('.campus-feature__grid');
    $campusGridItems = $campusGrid.find('.campus-feature__item');
    $modalCarousel = $('.campus-feature__carousel');
    $carousel = $modalCarousel.find('.carousel__wrapper');
    $bodyHtml = $('body, html');
    $openers = $campusGrid.find('.campus-feature__item-opener');
    $slides = $carousel.find('.slick-slide');
    $closeButtons = $carousel.find('.campus-feature__close');
    $headerPlacer = $campusGrid.find('.campus-feature__cell-header-placer');
    $header = $campusFeature.find('.campus-feature__header');
  }

  const addHoverEvents = () => {    
    $campusGridItems.on('mouseenter', () => {
      $campusGrid.addClass(HOVER_CLASS_NAME);
    });

    $campusGridItems.on('mouseleave', () => {
      $campusGrid.removeClass(HOVER_CLASS_NAME);
    });
  }

  const addFocusEvents = () => {
    $openers.on('focus', (e) => {
      $campusGrid.addClass(HOVER_CLASS_NAME);
      $(e.target).closest('.campus-feature__item').addClass(FOCUS_CLASS_NAME);
    });

    $openers.on('blur', (e) => {
      $campusGrid.removeClass(HOVER_CLASS_NAME);
      $('.campus-feature__item').removeClass(FOCUS_CLASS_NAME);
    });
  }

  const setMinButtonHeightVariable = () => {
    $campusGrid
      .css(
        {'--min-button-height': `${
          $campusGridItems.last().find('.campus-feature__item-title').outerHeight()
          }px`
        }
      );
  }

  const setMinCellHeightSmScreens = () => {
    $('.campus-feature__cell').each((index, item) => {
      const $cell = $(item);

      $cell.css({'--min-height': `${$cell.outerWidth() * 0.89}px`});
    });
  }

  const setHeaderProperties = () => {
    // reset properties
    $header.css(
      {
        '--campus-grid-header-width': '0px',
        '--campus-grid-header-height': '0px'
      });

    $headerPlacer.css({'--campus-grid-header-height': '0px'});

    // set properties
    if ($header.outerHeight() >= $headerPlacer.outerHeight()) {
      $header.css(
        {
          '--campus-grid-header-width': `${$headerPlacer.outerWidth()}px`,
        });
  
      $headerPlacer.css({'--campus-grid-header-height': `${$header.outerHeight()}px`});
    } else {
      $header.css(
        {
          '--campus-grid-header-width': `${$headerPlacer.outerWidth()}px`,
          '--campus-grid-header-height': `${$headerPlacer.outerHeight()}px`
        });
    }
  };

  const carouselModalToggle = () => {
    const closeModal = () => {
      $openers.attr('aria-expanded', 'false');
      $modalCarousel.attr('aria-hidden', 'true');
      $bodyHtml.removeClass('js-fixed');
      $lastClickedOpener.focus();
    }
    
    $openers.each((index, item) => {
      const $currentOpener = $(item);

      $currentOpener.on('click', () => {
        $currentOpener.attr('aria-expanded', 'false');
        $carousel.slick('slickGoTo', index, true);
        $modalCarousel.attr('aria-hidden', 'false');
        $bodyHtml.addClass('js-fixed');
        $lastClickedOpener = $currentOpener;

        setTimeout(()=> {
          $($slides[index]).find('.campus-feature__close').focus();
        }, 100);
      });
    });

    $closeButtons.on('click', () => {
      closeModal();
    });

    $(document).on('keydown', (e) => {
      if (e.keyCode === 27 && $modalCarousel.attr('aria-hidden') === 'false') {
        closeModal();
      }
    });
  };

  const keyboardNav = () => {
    const $nextBtn = $modalCarousel.find('.slick-next');
    const $prevBtn = $modalCarousel.find('.slick-prev');
    const $closeButtons = $carousel.find('.campus-feature__close');
    const $slides = $carousel.find('.slick-slide');
    const $lastSlideLastFocusable = $slides.last().find('a, button').last();
    const $document  = $(document);

    $document.on('keydown', (e) => {
      if (e.keyCode === 27) {
        $('.campus-feature__item').removeClass(FOCUS_CLASS_NAME);
        $campusGrid.removeClass(HOVER_CLASS_NAME);
      }
    });

    $nextBtn.on('keydown', (e) => {
      if (!e.shiftKey && e.keyCode === 9) {
        e.preventDefault();
        if ($prevBtn.hasClass('slick-disabled')) {
          $modalCarousel.find('.slick-active .campus-feature__close').focus();
        } else {
          $prevBtn.focus();
        }
      }
    });

    $prevBtn.on('keydown', (e) => {
      if (e.shiftKey && e.keyCode === 9) {
        e.preventDefault();
        if ($nextBtn.hasClass('slick-disabled')) {
          $modalCarousel.find('.slick-active a, slick-active button').last().focus();
        } else {
          $nextBtn.focus();
        }
      }
    });

    $closeButtons.on('keydown', (e) => {
      if (e.shiftKey && e.keyCode === 9 && $prevBtn.hasClass('slick-disabled')) {
        e.preventDefault();
        $nextBtn.focus();
      }
    });

    $lastSlideLastFocusable.on('keydown', (e) => {
      if (!e.shiftKey && e.keyCode === 9) {
        e.preventDefault();
        $prevBtn.focus();
      }
    });
  }

  const init = () => {
    if (!Boolean($('.campus-feature').length)) {
      return;
    };
    
    defineVariables();
    addHoverEvents();
    addFocusEvents();
    setMinButtonHeightVariable();
    setMinCellHeightSmScreens();
    carouselModalToggle();
    keyboardNav();

    $window.on('resize load', () => {
      setMinButtonHeightVariable();
      setMinCellHeightSmScreens();
      setHeaderProperties();
    });
  }

  return {
    init,
  }
})();

export default campusFeature;
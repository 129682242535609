const goToTop = (() => {
  const init = () => {
    const $firstFocusable = $('button, a, input, [tab-index]').first();

    $('.go-to-top-button').on('click', () => {
      $('html, body').animate({scrollTop: 0}, 300, () => {
        $firstFocusable.focus();
      });
    });
  }

  return {
    init,
  }
})();

export default goToTop;
const scrollbarWidth = (() => {
  const setScrollBarWidth = () => {
      const $outer = $('<div>').css({visibility: 'hidden', width: 100, overflow: 'scroll'}).appendTo('body');
      const widthWithScroll = $('<div>').css({width: '100%'}).appendTo($outer).outerWidth();
      $outer.remove();

      $('body').css('--scrollbar-width', `${100 - widthWithScroll}px`);
  };
  
  const init = () => {
    setScrollBarWidth();

    let scrollbarTimeout = null;
    
    $(window).on('resize', () => {

      if (scrollbarTimeout) {
        clearTimeout(scrollbarTimeout);
      }

      scrollbarTimeout = setTimeout(() => {
        setScrollBarWidth();
      }, 250);
    });
  }

  return {
    init,
  }
})();

export default scrollbarWidth;
import "@babel/polyfill";
import "@accessible360/accessible-slick";
import "object-fit-images";

import accordion from './object/accordion';
import inlineVideo from './object/video';
import column from './object/column';
import instagram from './object/instagram';
import twitterLoader from './object/twitter';
import facultyTable from './object/faculty-table';
import catalogProgram from './object/catalog-program';
import carousel from './object/carousel';
import programFinder from './object/program-finder';
import calendar from './object/calendar';
import catalog from './object/catalog';
import tableScroll from './object/scroll-table';
import goToTop from './object/goToTop';
import newsCarousel from "./object/news-carousel";
import campusFeature from "./object/campus-feature";
import sectionNav from "./interface/section-nav";
import tabs from "./object/tabs";
import programsCarousel from './object/programs-carousel';
import heroVideo from './object/hero-video';
import mainHeader from './object/main-header';
import gallery from './object/gallery';
import alert from './object/alert';
import footerSockDetector from './object/footer-sock-detector';
import featuredCalendarFilter from './object/featured-calendar-filter';
import scrollbarWidth from './util/scrollbar-width';

import lastWord from "./util/last-word";
import scrollTrigger from "./util/scroll-trigger";
import focusTriggersScrollEvent from "./util/focus-triggers-scroll-event";

window.$ = require("jquery");
window.jQuery = require("jquery");
const objectFitImages = require("object-fit-images");

$(() => {
  scrollbarWidth.init();
  sectionNav.init();
  footerSockDetector.init();
  accordion.init();
  tabs.init();
  inlineVideo.init();
  //instagramLoader.init();
  instagram.init();
  twitterLoader.init();
  facultyTable.init();
  calendar.init();
  catalogProgram.init();
  carousel.init();
  programFinder.init();
  catalog.init();
  tableScroll.init();
  objectFitImages();
  goToTop.init();
  gallery.init();
  newsCarousel.init();
  programsCarousel.init();
  campusFeature.init();
  heroVideo.init();
  mainHeader.init();
  alert.init();
  featuredCalendarFilter.init();

  lastWord.init();
  scrollTrigger.init();
  focusTriggersScrollEvent.init();

  $(window).on('load', () => {
    setTimeout(() => {
      $('body').addClass('loaded');
    }, 300);
  });

  setTimeout(() => {
    $('body').addClass('loaded'); // in case load event won't fire
  }, 2000);

  // Run after instagram and others have loaded
  $(window).on("load resize", () => {
    setTimeout(() => {
      column.init();
    }, 100);
  });

});

const twitterLoader = (() => {
  const init = () => {
    if (!$(".twitter__feed").length) {
      return;
    }

    // eslint-disable-next-line global-require

    const tweetun = $(".twitter__feed").data("un");

    function urls(text, entity) {
      return text.replace(
        entity.url,
        `<a href="${entity.expanded_url}" target="_blank">${entity.display_url}</a>`
      );
    }

    function users(text) {
      return text.replace(
        /@([a-zA-Z0-9_]+)/g,
        '<a href="https://twitter.com/$1" target="_blank">@$1</a>'
      );
    }

    function hashtags(text, entity) {
      const hash = entity.tag;
      return text.replace(
        `#${hash}`,
        `<a href="https://twitter.com/hashtag/${hash}?src=hash" target="_blank">#${hash}</a>`
      );
    }

    function entities(text, entities) {
      if (entities.urls && entities.urls.length) {
        entities.urls.forEach((entity) => (text = urls(text, entity)));
      }

      if (entities.hashtags && entities.hashtags.length) {
        entities.hashtags.forEach((entity) => (text = hashtags(text, entity)));
      }

      text = users(text);

      return text;
    }

    $.getJSON(
      "/assets/api/server.php?type=timeline&screen_name=" + tweetun,
      function (data) {
        data.forEach((tweet, i) => {
          const date = dayjs(tweet.created_at).format("MMMM DD");
          if (tweet.text.indexOf("RT ") === 0) {
            let text = tweet.text.slice(2);
            text = text.replace(":", "");
            text = entities(text, tweet.entities);
            $(".twitter__feed").append(
              `<div class="twitter__item"><div class="twitter__body">${text}<div><time class="twitter__time">${date}</time></div>`
            );
            const $tweet = $(".twitter__item").eq(i);
            $tweet.find("a:first").wrap('<div class="rt">');
          } else {
            const text = entities(tweet.text, tweet.entities);
            $(".twitter__feed").append(
              `<div class="twitter__item"> <div class="twitter__body">${text}<div><time class="twitter__time">${date}</time></div>`
            );
          }
        });
      }
    );
  };

  return {
    init,
  };
})();

export default twitterLoader;

const gallery = (() => {
  const TABLET_BREAKPOINT = 700;

  function addOverlay() {
    $(this).siblings('.gallery__item').addClass('gallery__item--overlay');
  }

  function removeOverlay() {
    $(this).siblings('.gallery__item').removeClass('gallery__item--overlay');
  }

  const init = () => {
    let debounceTimeout;
    const $window = $(window);

    if (!$('.gallery').length) {
      return;
    }
    
    $window.on('load resize', () => {
      clearTimeout(debounceTimeout);
      debounceTimeout = setTimeout(() => {
        if ($window.width() >= TABLET_BREAKPOINT) {
          $('.gallery__item').on('mouseenter focus', addOverlay);
          $('.gallery__item').on('mouseleave blur', removeOverlay);

          $(document).on('keydown', (e) => {
            if (e.keyCode === 27) {
              $('.gallery__item').blur();
              $('.gallery__item').removeClass('is-visible');
            };
          });

          $('.gallery__item').on('mouseenter', (e) => {
            $('.gallery__item').removeClass('is-visible');
            $(e.currentTarget).addClass('is-visible');
          });
      
          $('.gallery__item').on('mouseleave', (e) => {
            $('.gallery__item').removeClass('is-visible');
          });
        } else {
          $('.gallery__item').off();
        }
      }, 250)
    })
  }

  return {
    init,
  }
})();

export default gallery;
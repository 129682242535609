/**
 * News carousel display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if news carousel present
 */

const carousel = (() => {
  // Render carousel
  function renderCarousel() {
    const $carousel = $('.news-carousel__wrapper');

    $carousel.on('init', () => {
      $('.slick-active').last().addClass('slick-active--last');
    })

    $carousel.on(
      'beforeChange',
      (event, slick, currentSlide, nextSlide) => {
        const $lastActiveSlide = slick.$slides.filter(`[data-slick-index=${nextSlide + 2}]`);

        slick.$slides.removeClass('slick-active--last');
        $lastActiveSlide.addClass('slick-active--last');
      },
    );

    $carousel.slick({
      dots: false,
      infinite: false,
      speed: 300,
      slidesToShow: 3,
      responsive: [
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    });
  }

  const init = () => {
    if (!$('.news-carousel__wrapper').length) {
      return;
    }

    renderCarousel();
  };

  return {
    init,
  };
})();

export default carousel;
